/* styles.css */
:root {
  --primary-color: #E82828;
  /* couleur principale */
  --text-color: #000;
  /* couleur du texte */
  --font-size: 13px;
  /* Définition d'une variable pour la taille de police */
}

.titre-header {
  color: #E82828;
  font-size: 20px;
  font-style: normal;
  font-weight: '400';
  font-family: Montserrat;
}

.big-title {
  font-size: 14px;
  font-weight: bold;
  color: '#000000' !important;
  font-family: Montserrat;
}

.title-en-tete {
  font-size: 18px;
  font-weight: '500';
  margin-bottom: 20px;
  color: '#333' !important;
  font-family: Montserrat;
}

.litle-title {
  color: #666;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  font-family: Montserrat;
}

/* Style pour les sous-titres */
.sub-title {
  font-size: 22px;
  font-weight: bold;
  color: '#555';
  font-family: Montserrat;
}

/* Style pour les petits titres */
.small-title {
  font-size: 16px;
  font-weight: 400;
  color: '#000000' !important;
  font-family: Montserrat;
}

.telephone-span {
  margin-left: 90px;
  font-family: Montserrat;
}

.clientCart {
  display: 'flex';
  align-items: 'center';
  justify-content: 'space-between';
  margin: 5px;
  box-shadow: '0px 4.032px 30.24px 0px rgba(0, 0, 0, 0.05)';
  background-color: '#FFFFFF';
  /* Retirez les guillemets doubles ici */
  padding: 8px;
  border-radius: 8px;
  margin-top: 10px;
  color: "#000";
  border: "1px solid rgba(0, 0, 0, 0.05)";
  font-family: Montserrat;
}

.small-texte {
  color: rgba(0, 0, 0, 0.60);
  font-family: Montserrat;


  font-family: Montserrat;
  font-size: 14.112px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}