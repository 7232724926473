.validateButton{
    color: white;
    font-family: Montserrat;
    font-size: 17px;
    padding: 8px;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: 1px solid #E82828;
    background-color: #E82828;

    }
    .validateButton:hover{
    color: white;
    font-family: Montserrat;
    font-size: 17px;
    padding: 8px;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    background-color: #E82828;
    }

    .title-client{
        color: #000;
        font-size: 16.128px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 10px;
    }
    
    .ajout-client{
        padding: 20px;
    }
    .commande-title{
        color: #000;
        font-size: 16.128px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-top: 20px;
    }
    .title-paiement{
        color: #000;
        font-size: 16.128px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-top: 20px;
    }
    .payment-container{
        padding-top: 50px;
    }
    .error-msg{
        color: red;
    }
    .error-message {
        color: red;
      }
      
      .inputMesure{
        height: 42px;
        width: 120px;
     
      }
      .mesure-client h6{
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        color: #000000;
        
      }
      .mesure-card{
        border: 1px solid #DDE5E9;
        border-radius: 9px;
        padding: 10px;

      }