
.modeleImage input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
  .modeleImage {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  .modeleLabel {
    background: #e82828;
    border: 1px solid #e82828;
    border-radius: 5px;
    color: #ffffff;
    padding: 8px 20px;
    font-size: 20px;
    font-weight: bold;
  }


  .editCommande{
    /* background: #000; */
    display: flex;
    justify-content: space-around;
  }
  .custom-file-input {
    width: 100% !important; /* ou toute autre largeur souhaitée */
  }
  