.bottom-tab {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.2);
  /* Autres styles de votre choix */
}

.bottom-tab-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #B9B8B8;
  font-family: Montserrat;
  /* font-weight:bold */

}

/* Style des icônes */
.bottom-tab-link svg {
  font-size: 24px;
  color: #B9B8B8;
  font-weight:bold
}

/* Style du texte sous les icônes */
.bottom-tab-link span {
  font-size: 14px;
  margin-top: 5px;
  font-style: normal;
  font-weight:bold
}
.active-link {
  color: var(--primary-color); 
}
.active-link svg {
  fill: var(--primary-color);
}
