
.error-msg.show {
    display: block; /* Pour afficher le message d'erreur */
  }
  .error-msg {
    display: none; /* Pour masquer le message d'erreur par défaut */
  }
  
.add-client .title{
    font-size: 17px;
    font-weight: 500;
    margin-left: 13px;
}
.add-client .space-client input[type="text"],.add-client .space-client input[type="number"],.add-client .space-client input[type="tel"]{
    width: 100%;
    border: 1px rgba(0, 0, 0, 0.4) solid;
    border-radius: 3px;
    height: 32px;
    display: block;
}
.text-form{
    font-weight: 400;
    font-size: 14;
 
}

#mesureTitle {
    width:100px;
    display: block;
    border: 1px rgba(0, 0, 0, 0.4) solid;
    border-radius: 3px;
    /* display: block; */
    /* height: 32px; */
    /* display: inline-block; */
}


.add-client .space-mesure input[type="number"]{
    width: 40%;
    border: 1px rgba(0, 0, 0, 0.4) solid;
    border-radius: 3px;
    height: 32px;
    display: inline-block;
}
.add-client .space-mesure span{
    width: 60%;
    display:inline-block;
}
.title{
    color: #000;
font-family: Montserrat;
font-size: 16.13px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.403px;
}
/* .validateButton:hover{
background-color: azure;
color: red;
} */