
.image-container {
  display: flex;
  flex-wrap: wrap;
  gap:2%;
  height: 100vh;
  justify-content: center;
}

.image-wrapper {
 
  /* flex-direction: column; */
  align-items: center;
  /* text-align: center; */

}

.image-wrapper img {
  width: 180px;
  height: 180px;
  /* display: block; */
  border-radius: 50%;
  object-fit: cover;
}

.image-wrapper div {
  /* margin-top: 52px;  */
  /* font-weight: bold;
  width: 40px; */
}
