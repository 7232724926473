.com{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px; 
}
.comcom {
  text-align: center;
  }
.section1{
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    background: #FFF;
    max-width: 500pxpx;
    margin: auto;
    
}
.small-title-name{
    font-weight: 600;
}
.facture-container {
    display: flex;
    justify-content: flex-end; /* Alignez le contenu à droite de son conteneur */
    margin-top: 10px; /* Ajustez la marge supérieure selon vos besoins */
    margin-right: 10px;
  }
  .date{
    margin-top: 25px;
    margin-left: 70px ;
  }
  .tayeur{
    color: #000;
    font-family: Montserrat;
    font-size: 11.81px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 17.715px */
  }
  .tayeur_p{
    color: #000;
    font-family: Montserrat;
    font-size: 11.81px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 17.715px */
  }
  .table_tr{
    background-color: #000;
    color: white;
  }
  .gerer-facture{
    margin-top: 50px;
  }
.prix{
    display: block;
    justify-content: flex-end;
}
/* .text-border{
    margin-left: 50px;
    
  } */
.btn-gray{
    background-color: gray;
    color: #FFF;
    margin-left: 10px;
    border: none;
}
.footer{
    margin-top: 40px;
}
.bouton-facturer:hover {
    /* Styles à appliquer au survol du bouton */
    /* Par exemple, changer la couleur de fond ou la couleur du texte */
    background-color: #00aeff; /* Rouge en exemple */
    color: #ffffff; /* Blanc en exemple */
  }
  .imprimente{
    display: flex;
    justify-content: space-between;
  }
 