.custom-select-wrapper {
    position: relative;
    width: fit-content;
}

.custom-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #ffffff;
    border: 1px solid #ccc;
    padding: 8px 30px 8px 10px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
}

.custom-select option {
    padding: 8px;
}

.flag-icon {
    width: 20px;
    margin-right: 5px;
}

/* Stylisation de la flèche déroulante */
.custom-select::after {
    content: '\25BC'; /* flèche déroulante Unicode */
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #555;
}
