.validateButton{
    color: white;
    font-family: Montserrat;
    font-size: 17px;
    padding: 8px;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    background-color: #E82828;
    }

    .title-client{
        color: #000;
        font-size: 16.128px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 10px;
    }
    
    .ajout-client{
        padding: 20px;
    }
    .commande-title{
        color: #000;
        font-size: 16.128px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-top: 20px;
    }
    .title-paiement{
        color: #000;
        font-size: 16.128px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-top: 20px;
    }
    .payment-container{
        padding-top: 50px;
    }
    .error-msg{
        color: red;
    }
    .error-message {
        color: red;
      }
      
 