/* styles.css (ou App.css) */

.alert-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 9999;
    text-align: center;
  }
  
  .alert-modal p {
    margin-bottom: 15px;
  }
  
  .alert-modal button {
    background-color: red;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .alert-modal button:hover {
    background-color: #0056b3;
  }
  .ajout-client{
    background-color: #fff;
    border-radius: 29px;
    margin: 4px;
  }
  .mesure-du-client{
    color: #000000;
    font-size: 16.13px;
    font-family: Montserrat;
    font-weight: 500;
    margin-bottom: 15px;
  }
  