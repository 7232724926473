.image-container{
    /* background: url('image/bg1.jpg') center no-repeat; */
    /* background-color: #fff; */
    background-size: cover;
    height: 100vh;
}

.form-container{
    background-color: #fff;
    display: flex;
    justify-content: center;
    
}
.a{
    text-decoration: none;
}

.form-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    margin-top: -70px;
}

.form-box h4{
    font-weight: bold;
    color: #fff;
}

.form-box .form-input {
    position: relative;
}

.form-box .form-input input{
    width: 100%;
    height: 50px;
    outline: none;
    border: none;
    margin-bottom: 20px;
    
    border-radius: 5px;
   
    background: #f4f8f7;
    padding-left: 45px;
}

.form-box .form-input span{
    position: absolute;
    top: 8px;
    padding-left: 20px;
    color: #777;
}

.form-box .form-input input::placeholder{
    padding-left: 0px;
}



.form-box .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before{
    background-color: #dc3545;
    border: 0px;
}

.form-box button[type="submit"]{
    border: none;
    cursor: pointer;
    width: 100%;
    /* height: 40px; */
    border-radius: 5px;
    background-color: #dc3545;
    color: #fff;
    font-weight: bold;
    transition: 0.5s;
}

.form-box button[type="submit"]:hover{
    -webkit-box-shadow: 0px 9px 10px -2px rgba(0,0,0,0.55);
    -moz-box-shadow: 0px 9px 10px -2px rgba(0,0,0,0.55);
    box-shadow: 0px 9px 10px -2px rgba(0,0,0,0.55);
}

.forget-link, .register-link, .login-link{
    color: #fff;
    font-weight: bold;
    text-decoration: none;
}

.forget-link:hover, .register-link:hover, .login-link:hover{
    color: #292525;
}
.text-red{
    color: red;
    text-decoration: none;
}


/* .formpage{

    padding-bottom: 30px;
} */

.text-white{
    text-decoration: none;
}

