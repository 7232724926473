/* ProfilClient.css */

.container {
    flex: 1;
  }
  
  /* Styles pour l'en-tête */
  .infoTitle {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    background-color: #fff;
  }
  
  /* Styles pour le titre "Informations du client" */
  .title {
    color: #3A3A3A;
    font-weight: 700;
    font-size: 15px;
    margin-left: 5px;
  }
  
  /* Styles pour le bouton "Modifier" */
  .bouton {
    color: orange; /* Utilisez votre propre couleur ici */
  }
  
  /* Styles pour le conteneur d'informations du client */
  .infoClient {
    background-color: white;
    padding: 5px;
    width: 100%;
    margin: 5px;
    margin-top: 100px;
  }
  .profiles{
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
    background-color: white;
    padding: 5px;
    width: 100%;
    margin-top: 10px;
    cursor: pointer;
  /* } */
  }
  /* Styles pour les icônes et le texte */
  .iconText {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 5px;
  }
  .params{
    align-items: center;
    flex-direction: row;
    padding: 5px;
  }
  /* Styles pour les images (icônes) */
  .image {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
  
  /* Styles pour le texte des informations du client */
  .text {
    font-weight: 400;
    font-size: 17px;
    color: #212529;
  }
  
  /* Styles pour le bouton "Procéder au paiement" */
  .button {
    align-items: center;
    background-color: #00f; /* Utilisez votre propre couleur ici */
    padding: 20px;
    border-radius: 10px; /* Utilisez votre propre valeur de rayon */
    width: 80%;
    margin: 40px;
  }
  
  /* Styles pour le texte du bouton "Procéder au paiement" */
  .textButton {
    color: white;
  }
  